<template>
	<div class="monitor_video">
		<div class="videoBox"  id="playWind" style="z-index: 9999999"></div>
	</div>
</template>

<script>
import EZUIKit from 'ezuikit-js';

export default {
	name: 'MonitorVideo',
	model: {
		prop: 'videoRestart',
		event: 'getRestart'
	},
	props: {
		monitorVideo: {
			type: String
		},
		videoRestart: {
			type: Boolean
		}
	},
	watch: {
		monitorVideo(newVal,oldVal) {
			console.log(newVal,888)
			if(newVal) {
				this.monitorVideo = newVal;
				// if(this.player != null) {
				// 	this.removeVideo()
				// }
				this.removeVideo()
				this.$nextTick(() => {
					this.getVideo();
				})
			}
		},
		videoRestart(newVal,oldVal) {
			console.log(newVal,'-----重启')
			if(newVal) {
				this.$nextTick(() => {
					this.getVideo()
					this.$emit('getRestart',false)
				})
			}
		},
	},
	data() {
		return {
			player: null,
		}
	},
	created() {
		this.$nextTick(() => {
			console.log('进来了1')
			this.getVideo();
		})
	},
	beforeDestroy(){
	    if(this.play) {
			console.log('进来了2')
			this.player.stop();
		}
	},
	methods: {
		// 销毁原来的视频
		removeVideo() {
			console.log(this.player,'-----销毁视频')
			var stopPromise = this.player.stop();
			stopPromise.then((data) => {
				//this.player = null;
			});
		},
		// 初始化
		getVideo() {
			let url='https://open.ys7.com/api/lapp/token/get'
			fetch(url, {
				method: 'post',
				headers:{
					"Content-Type": "application/x-www-form-urlencoded"
				},
				body:"appKey=ebcd8f852ccb4ea59898de48e17d4778&appSecret=bff65478474c51f15bd968af6882bb45",
				mode: 'cors'
			}).then(response => response.json()).then(res=>{
				if(this.player != null) {
					this.removeVideo()
				}
				this.player = new EZUIKit.EZUIKitPlayer({
					id: 'playWind', // 视频容器ID
					accessToken: res.data.accessToken,
					url: this.monitorVideo,
					audio: false,
					width: 279,
					height: 172,
					template: "pcLive",
					handleError: (err) => {
					    console.log(err,'----err')
					},
				});
				// this.player.closeSound();
				// 初始化时，默认静音
				this.player.Theme.themeData.footer.btnList[2].defaultActive = 0;
				window.player = this.player;
			})
		}
	}
}
</script>

<style lang="scss" scoped>
	.monitor_video {
		.videoBox {
			
		}
	}
</style>