<template>
	<div class="farming_distribution fl_c">
		<!-- {{ $store.state.travel.item.name }} -->
		<div class="title_common">
			<img src="@/assets/img/header/indicate.png"/>
			<span>产业介绍</span>
		</div>
		<div class="zindex1">
			<div class="park">
				<div class="sub_title">{{ $store.state.travel.item.name }}</div>
				<div class="introduct_box">
					<span class="lt_icon"></span>
					<span class="rt_icon"></span>
					<img src="@/assets/img/bg_sl01.png"/>
					<vue-seamless-scroll
						:data="listData"
						:class-option="classOption"
						class="warp"
						ref="vueSeamlessScroll"
					>
						<div style="height: 100%;margin: 10px 0;line-height: 25px;">
							{{ detailInfo }}
						</div>
					</vue-seamless-scroll>
				</div>
			</div>
			<div class="tourism_content_box">
				<div class="sub_title" @click="videoRestart = !videoRestart">景点看点概览</div>
				<div class="tourism_content">
					<div class="fl_c ju_b lt">
						<div class="backimg fl fl_c ju_b al_c">
							<div class="num-color">23564</div>
							<div>人流量/日</div>
						</div>
						<div class="backimg fl fl_c ju_b al_c">
							<div class="num-color">23564</div>
							<div>盈利总额</div>
						</div>
					</div>
					<div class="rt">
						<MonitorVideo 
							v-model="videoRestart" 
							:monitorVideo="$store.state.travel.item.monitor_video"
							@getRestart="getRestart"
						></MonitorVideo>
						<!-- <video 
							src="https://oos-cn.ctyunapi.cn/supply-video/%E6%B1%9F%E5%B1%B1%E6%83%85MV2022.11.8%E5%B8%A6%E5%8F%B0%E6%A0%87.mp4"
							:controls="true"
							autoplay muted 
						></video> -->
						<!-- <Video
							v-show="true"
							:videoSrc="videoSrc"
							:videoPoster="videoPoster"
							:width="300"
							:height="120"
							:autoplay="true"
							:controls="true"
							:loop="false"
							:muted="false"
							preload="auto"
							:showPlay="true"
							:playWidth="96"
							zoom="contain"
						/> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MonitorVideo from "@/components/MonitorVideo/MonitorVideo";
import Video from "@/components/Video/Video";
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "culturalTourismVideo",
  props: {
    cropData: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
	  getRestart(e) {
		  console.log(e,'-----e')
		  this.videoRestart = e;
	  }
  },
  mounted() {
    this.$nextTick((e) => {});
  },
  components: {
	MonitorVideo,
    Video,
    vueSeamlessScroll,
  },
  data() {
    return {
      // 视频链接支持https或require本地地址
      videoSrc: `https://oos-cn.ctyunapi.cn/supply-video/%E6%B1%9F%E5%B1%B1%E6%83%85MV2022.11.8%E5%B8%A6%E5%8F%B0%E6%A0%87.mp4`,
      // 视频封面支持https或require本地地址
      videoPoster: ``,
      listData: [1, 2],
	  detailInfo: this.$store.state.travel.item.details,
	  videoRestart: false
    };
  },
  computed: {
  	introductData() {
  		return this.$store.state.travel.item.details
  	},
  	classOption() {
  		return {
  			step: 0.2, // 数值越大速度滚动越快 
  			//自己是定时器轮询后端接口，由于返回的数据是1s一更新，要小于后端更新频率，不然最下面的数据还没更新
  			limitMoveNum: 2, // 开始无缝滚动的数据量 this.datalist.length
  			hoverStop: true, // 是否开启鼠标悬停stop
  			direction: 1, // 0向下 1向上 2向左 3向右
  			openWatch: true, // 开启数据实时监控刷新dom
  			singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
  			singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
  			waitTime: 0, // 单步运动停止的时间(默认值1000ms)
  		}
  	},
  },
  watch:{
  	introductData (newVal, oldVal) {
		console.log(newVal,'----newVal')
		console.log(oldVal,'----oldVal')
  		if(newVal) {
  			this.detailInfo = newVal;
  			this.$nextTick(() => {
  				this.$refs.vueSeamlessScroll.reset()
  			})
  		}
  	}
  },
};
</script>

<style scoped lang="scss">
.farming_distribution {
  width: 100%;
  height: 100%;
}

.title_common {
  width: 100%;
  height: 42px;
  line-height: 42px;
  display: flex;
  align-items: center;
  padding: 9px 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url(../../assets/img/header/title_03.png);
  letter-spacing: 1px;
  img {
  	width: 24px;
  	height: 24px;
  	margin-right: 16px;
  	margin-top: 2px;
  }
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
  margin-top: 12px;
}
.park {
	margin-bottom: 8px;
}
.tourism_content_box {
	.tourism_content {
		display: flex;
		align-items: center;
		height: 172px;
		justify-content: space-between;
		.lt {
			height: 100%;
			flex: 1;
		}
		.rt {
			margin-left: 11px;
			height: 100%;
			width: 70%;
			background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
			box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
			/deep/ .m-video {
				height: 100% !important;
				width: 100% !important;
				video {
					height: 100% !important;
					width: 100% !important;
				}
			}
			video {
				height: 100% !important;
				width: 100% !important;
			}
		}
	}
}
.sub_title {
	font-size: 16px;
	color: #FFFFFF;
	font-weight: 500;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	font-family: PingFang SC-Bold, PingFang SC;
	background-image: url(../../assets/img/header/pop_02.png);
	width: 100%;
	height: 42px;
	line-height: 33.33px;
	padding-left: 23px;
	margin-bottom: 8px;
	
}
.introduct_box {
  background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
  box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
  font-family: PingFang SC-Bold, PingFang SC;
  height: 154px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  img {
	  width: 100%;
	  height: 136px;
	  position: relative;
  }
}
.bigbg {
  background-repeat: no-repeat;
  background-size: 98% 98%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url("../../assets/img/farm1.png");
  background-position: center;
}
.flex100 {
  flex: 100;
}
.flex350 {
  flex: 350;
}
.backimg {
  border-top: 5px solid #0961dd;
  font-family: PingFang SC-Bold, PingFang SC;
  font-size: 14px;
  color: #7589B1;
  letter-spacing: 1px;
  padding: 15px;
  background-image: linear-gradient(0deg, rgba(21,47,103,0.00) 0%, #152F67 100%);
}
.num-color {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0;
}
.titleimg {
  background-repeat: no-repeat;
  background-size: 60% 90%;
  font-family: PingFang SC-Bold, PingFang SC;
  background-image: url("../../assets/img/list2.png");
  background-position: center;
  padding: 15px;
  color: rgb(0, 255, 218);
}

.warp {
  width: 350px;
  height: 120px;
  //margin: 20px auto 0;
  //padding-top: 15px;
  overflow: hidden;
  //margin: 15px auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  position: absolute;
  top: 20px;
  bottom: 20px;
  letter-spacing: 1px;
}
.lt_icon {
  position: absolute;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
}
.rt_icon::after {
  content:'';
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../../assets/img/header/bevel_01.png);
  width: 10px;
  height: 10px;
  transform: scaleX(-1);
  position: absolute;
  right: 0;
  top: 0;
}
</style>
