<template>
	<div class="agricultural_production fl_c">
		<div class="title_common">
			<img src="@/assets/img/header/indicate.png"/>
			<span>营业额度概览</span>
		</div>
		<div style="width: 100%; height: 195px;" class="echarts_box">
			<span class="lt_icon"></span>
			<span class="rt_icon"></span>
			<div ref="agriculturalBar" style="width: 100%; height: 100%"></div>
		</div>
	</div>
</template>

<script>
import { getPlanningProductStatistics } from "@/utils/api/index";

export default {
	name: "culturalTourismCount",
	props: {},
	data() {
		return {
			countArr: [
				{ name: `规划项目`, num: 48, count: `4500.7` },
				{ name: `建设项目`, num: 6, count: `225.6` },
				{ name: `已完成项目`, num: 8, count: `379.2` },
			],
			mouthData: [],
			numberData: [],
			cropData: [],
			changePieInterval: null,
			right_bar: null,
			currentIndex: -1,
			talentsEducationStatistics: [],
			data: {},
			option: {
                grid: {
					left: '7%',
					right: '10%',
					top: '20%',
					bottom: '10%',
					containLabel: true
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
				    },
					backgroundColor: 'rgba(9, 24, 48, 0.5)',
					borderColor: 'rgba(75, 253, 238, 0.4)',
					textStyle: {
						color: '#CFE3FC',
					},
					borderWidth: 1,
					confine: true,
				},
				dataZoom: {
					show: true, // 为true 滚动条出现
					realtime: true,
					bottom: "0%",
					startValue: 0, // 从头开始。
					endValue: 3, // 一次性展示6个。
					type: "inside", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
				},
				legend: {
					textStyle: {
					  color: "#E6F7FF",
					},
					top: '5%',
					right: '10%'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					axisLabel: {
						color: '#A1A7B3',
						textStyle: {
							color: '#7589B1',
							padding: 10,
							fontSize: 12
						},
					},
					axisLine: { //坐标轴轴线相关设置。数学上的x轴
						show: true,
						lineStyle: {
							color: '#192a44'
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: '#192a44'
						},
					},
					axisTick: {
						show: false
					},
					data: ["2022-12", "2023-01", "2023-02", "2023-03", "2023-04", "2023-05"]
				},
				yAxis: [{
					type: 'value',
					name: '人流量',
					nameTextStyle: {
						color: "#7ec7ff",
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: '#233653',
						}
					},
					axisLine: {
						show: false
					},
					axisLabel: {
						show: true,
						margin: 10,
						textStyle: {
							color: '#7589B1',
							padding: 5,
							fontSize: 12
						}
					},
					axisTick: {
						show: false
					}
				}],
				series: [
					{
						name: '餐饮',
						type: 'line',
						smooth:true,
						stack: '总量',
						symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
						showAllSymbol: true,
						symbolSize: 7,
						itemStyle: {
							color: "#3DE6FF",
						},
						lineStyle: {
							width: 3,
							shadowColor: "#3DE6FF", //透明的颜色
							shadowOffsetX: 0,
							shadowOffsetY: 0,
							opacity: 1, //透明度
							shadowBlur: 18, //阴影大小
							type: "solid", //实线
						},
						areaStyle: {
							normal: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(61,230,255,0.8)'
									},
									{
										offset: 1,
										color: 'rgba(25,255,242, 0)'
									}
								], false),
							}
						},
						data: [100, 200, 80, 340, 500, 300]
					},
					{
						name: '民宿',
						type: 'line',
						smooth:true,
						stack: '总量',
						symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
						showAllSymbol: true,
						symbolSize: 7,
						itemStyle: {
							color: "#1968FF"
						},
						lineStyle: {
							width: 3,
							shadowColor: "#1968FF", //透明的颜色
							shadowOffsetX: 0,
							shadowOffsetY: 0,
							opacity: 1, //透明度
							shadowBlur: 18, //阴影大小
							type: "solid", //实线
						},
						areaStyle: {
							normal: {
								color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(25,104,255,1)'
									},
									{
										offset: 1,
										color: 'rgba(190,212,231, 0)'
									}
								], false),
							}
						},
						data: [500, 120, 300, 400, 500, 200]
					},
				]
			},
		};
	},
	methods: {
		echartsInit() {
			this.center_pie = this.$echarts.init(this.$refs.agriculturalBar);
			clearInterval(this.timeOut);
			this.timeOut = setInterval(() => {
				if (this.option.dataZoom.endValue == this.option.xAxis.data.length) {
					this.option.dataZoom.endValue = 3;
					this.option.dataZoom.startValue = 0;
				} else {
					this.option.dataZoom.endValue = this.option.dataZoom.endValue + 1;
					this.option.dataZoom.startValue = this.option.dataZoom.startValue + 1;
				}
				this.center_pie.setOption(this.option);
			}, 2000);
		},
		async getList() {
			let { data } = await getPlanningProductStatistics();
			this.countArr = [
				...data.completedProject,
				...data.ongoingProject,
				...data.planProject,
			];
			data.AttractInvestmentList.forEach((element) => {
				this.numberData.push(element.number);
				this.mouthData.push(element.yearOfMonth);
			});
			this.cropData = data.AttractInvestmentList;
			this.$nextTick((e) => {
				// this.option.xAxis.data = this.mouthData;
				// this.option.series[0].data = this.numberData;
				// this.option.series[1].data = this.numberData;
				this.echartsInit()
			});
		},
	},
	created() {
		this.getList();
	},
};
</script>

<style scoped lang="scss">
.agricultural_production {
  width: 100%;
  height: 100%;
}
.title_common {
	width: 100%;
	height: 42px;
	line-height: 42px;
	display: flex;
	align-items: center;
	padding: 9px 0;
	font-size: 18px;
	font-weight: 600;
	color: #ffffff;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	font-family: PingFang SC-Bold, PingFang SC;
	background-image: url(../../assets/img/header/title_03.png);
	letter-spacing: 1px;
	img {
		width: 24px;
		height: 24px;
		margin-right: 16px;
		margin-top: 2px;
	}
}

.item-contain {
  width: 95%;
  height: 260px;
  margin: 10px 20px;
}
.ab_style {
  width: 100%;
  height: 190px;
}
.fb_style {
  width: 100px;
  height: 250px;
}
.fl5 {
  flex: 2;
}
.zindex1 {
  z-index: 1;
  color: #ffffff;
}
.img {
  width: 40px;
  // height: 40px;
}
.countfont {
  color: rgb(0, 141, 236);
  font-size: 14px;
}
.countnum {
  font-size: 22px;
}
.item_box {
      border-radius: 20px 0px 0px 20px;
      margin-bottom: 8px;
      padding: 3px;
      display: grid;
      grid-template-columns: repeat(4,1fr);
      font-size: 14px;
      align-items: center;
      .title {
          padding-left: 25px;
      }
      .nums {
          color: #fff;
      }
      .total_title {
          text-align: center;
      }
      .total {
          color: #00E6E0;
      }
      .nums,.total {
          font-size: 16px;
          font-weight: 500;
          text-align: right;
          padding-right: 5px;
          .unit {
              font-size: 14px;
              font-weight: 400;
              color: #7589B1;
              margin-left: 10%;
              padding-right: 10px;
          }
      }
  }
  .item_box:nth-child(1) {
      background-image: linear-gradient(270deg, rgba(18,134,223,0.00) 0%, rgba(0,105,255,0.40) 98%);
  }
  .item_box:nth-child(2) {
      background-image: linear-gradient(270deg, rgba(18,134,223,0.00) 0%, rgba(0,230,224,0.40) 98%);
  }
  .item_box:nth-child(3) {
      background-image: linear-gradient(270deg, rgba(0,153,230,0.00) 0%, rgba(0,153,230,0.40) 97%);
  }
  .echarts_box {
	  margin-top: 12px;
      background-image: linear-gradient(0deg, rgba(20,26,38,0.80) 0%, rgba(32,45,70,0.29) 100%);
      box-shadow: inset 0px 0px 30px 0px rgba(45,93,117,0.2);
      position: relative;
      //height: 190px;
      width: 100%;
      .lt_icon {
          position: absolute;
          left: 0;
          top: 0;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url(../../assets/img/header/bevel_01.png);
          width: 10px;
          height: 10px;
      }
      .rt_icon::after {
          content:'';
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url(../../assets/img/header/bevel_01.png);
          width: 10px;
          height: 10px;
          transform: scaleX(-1);
          position: absolute;
          right: 0;
          top: 0;
      }
  }
</style>
