const TravelOnlyArr = [{
    "area": "",
    "image": "[{\"name\":\"国家地质森林公园.png\",\"data\":\"/file/202303/28efa6c378a140a8a95a243881166ce5.png\",\"uid\":1680252934308,\"status\":\"success\"}]",
    "address": "新罗区江山镇",
    "obj_id": "5",
    "latitude": "25.167232329206996",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.956209154577905, 25.167693430749999],[116.956271993920069, 25.16774305130004],[116.956611635986818, 25.167368441197528],[116.956897767457875, 25.16734715116257],[116.956925852146924, 25.167251200288746],[116.956976669124984, 25.167118784457802],[116.956484639253247, 25.167015529854989],[116.956481957438555, 25.167125987496576],[116.956527403754308, 25.16732736637579],[116.956209154577905, 25.167693430749999]]",
    "name": "龙岩国家地质森林公园",
    "details": "走进“江山景区”的石山园，但见幽幽青山，绿树成荫，老藤缠绕，瀑布飞流，鸟鸣山涧，竹海呼啸，游人到此，仿佛走进世外桃园。丞相岩、石观音、一线天、仙袋长啸、冲天蜡烛等大自然鬼斧神工的杰作，点缀于茂林修竹之间，令人称奇。 龙岩国家森林公园是一个不可多得的生物资源天然宝库，森林覆盖率达91%。这里植被保存相当完好，原始森林、天然阔叶林、次生常绿阔叶针叶混交林、中山草甸、竹林，层次分明；壳斗科、樟科、金缕梅科、木兰科、山茶科、木犀科、禾本科2600多种植物应有尽有；南方红豆杉、银杏、闽楠、桫椤、水杉等大量珍稀树种分布其间，有的甚至连绵成片，蔚为壮观。更有虎、豹、鹿、獐、猴、喜鹊、白鹇、红嘴兰鹊等走兽飞禽出没其中。",
    "tel": "",
    "id": 1487,
    "vRaddress": "",
    "longitude": "116.956685166195697"
  },
  {
    "area": "10",
    "image": "[{\"name\":\"微信图片_20230414160102.jpg\",\"data\":\"/file/202304/3e11739169564690881ee37f6a7add68.jpg\",\"uid\":1681524097303,\"status\":\"success\"}]",
    "address": "新罗区江山镇科山村",
    "obj_id": "12",
    "latitude": "25.225316403987669",
    "video": "",
    "type": "9",
    "linkman": "张信清",
    "use_state": "",
    "addArr": "[[116.970672910341833,25.225725145850454],[116.971271634692229,25.225859413423443],[116.972041463367447,25.225410864852819],[116.971122007579964,25.225102755094902],[116.97058740744086,25.225278900117537],[116.970672910341833,25.225725145850454]]",
    "name": "龙井瀑布",
    "details": "江山龙井瀑布的发源地，位于新罗区第一高峰，龙岩市第二高峰的黄连芋东脉，“王府点兵”山即‘’九十九墩‘’，海拔1440米。 远眺: 白缎悬晒于壁，与蓝天白云融为一体；近瞻，似缕缕银丝垂挂，疑为九天银河倾",
    "tel": "18505086988",
    "id": 1551,
    "vRaddress": "",
    "longitude": "116.971079225380791"
  },
  {
    "area": "",
    "image": "[{\"name\":\"446975b2f74806c40a9088ef1d914bc.jpg\",\"data\":\"/file/202304/9d703109d48a4c14b388bcbe9f3826af.jpg\",\"uid\":1681872492170,\"status\":\"success\"}]",
    "address": "新罗区江山镇铜砵村",
    "obj_id": "6",
    "latitude": "25.198804101740453",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.947098540097414, 25.1990747370644],[116.947063805172846, 25.198458366043599],[116.945991065067872, 25.198499269360813],[116.945346295448431, 25.198830564024696],[116.946098039973279, 25.199013576828996],[116.946213058317994, 25.1991686807321],[116.946646438575797, 25.199184833599457],[116.946801606280715, 25.199080175892068],[116.946801605607135, 25.199089885563943],[116.946975487664673, 25.199057997982791],[116.947098540097414, 25.1990747370644]]",
    "name": "石山园景区",
    "details": "位于江山景区，距龙岩城关25公里。园内多为中、低山及丘陵地貌、最高海拔黄连盂1815.2米，为龙岩市第一高峰。景区呈长条形，面积1712.5公顷。峰峦叠嶂，怪石嶙峋，碧波万顷，溪水潺潺。奇岩、灌丛、密林、梯田、村落，巧妙地组合在一起，构成层次分明、色彩斑斓的自然景观。景点有千米屏障、石观音、一线天、石狮圆梦、黛山闻涛、碧竹丹枫、竹坞鸟语、动物天堂等。其特点为山秀、石奇、林茂、水清，一派大自然风光。",
    "tel": "",
    "id": 1582,
    "vRaddress": "",
    "longitude": "116.946493936617131"
  },
  {
    "area": "30",
    "image": "[{\"name\":\"樱花观赏.jpg\",\"data\":\"/file/202303/05f92ce9eae14efab867a96b8755fc47.jpg\",\"uid\":1680253445751,\"status\":\"success\"}]",
    "address": "新罗区江山镇山塘村",
    "obj_id": "11",
    "latitude": "25.167373954990172",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.953699403360375, 25.166708614996512],[116.953276849378625, 25.166602724524736],[116.952987981034283, 25.166952921350269],[116.952816784564533, 25.167390236110361],[116.953030721768471, 25.167753901457299],[116.95311094794647, 25.167923647377709],[116.953485366395981, 25.167859725497134],[116.953742118996772, 25.167854303735645],[116.954100496704228, 25.167630188569248], [116.954400042066268, 25.167415908930494],[116.954448202329758, 25.167100220804038],[116.954309144481329, 25.166911180031622],[116.953699403360375, 25.166708614996512]]",
    "name": "樱花观赏",
    "details": "观赏作用樱花花朵极其美丽,为早春重要的观花树种,常用于园林观赏,盛开时节花繁艳丽,满树烂漫,如云似霞,极为壮观。可大片栽植造成“花海”景观,可三五成丛点缀于绿地形成锦团,也可孤植形成“万绿丛中一点红”之画意。",
    "tel": "",
    "id": 1584,
    "vRaddress": "",
    "longitude": "116.953608392228716"
  },
  {
    "area": "2",
    "image": "[{\"name\":\"db5f37ed9b114b578af8995f1bcc36fc.jpg\",\"data\":\"/file/202303/dd20cf2607ae4d988a5c06bd6ada56d6.jpg\",\"uid\":1678849302809,\"status\":\"success\"}]",
    "address": "新罗区江山镇双车村",
    "obj_id": "8",
    "latitude": "25.305967982886763",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.932726673311777, 25.305987609395928],[116.9330318281974, 25.306098745400345],[116.933117494721472, 25.305945845602444],[116.932823050949935, 25.305812874436654],[116.932726673311777, 25.305987609395928]]",
    "name": "双车自然温泉",
    "details": "驰名退迩的双车温泉，位于新罗区江山镇麻林溪畔，此溪发源于梅花山腹地上杭县步云乡，溪南面对海拔1800多米的岩顶山，青山翠谷草木茂盛，陡峭的峰峦迫使清溪折向而流。长年下渗雨水和地表径流循环到地壳深处而形成温泉，这也是地热的异常现象。据当地客家老人讲，此温泉始发于本世纪四十年代，先是发现农田中有几个小洞，连续冒出浑浊的热水，而后逐日增多加大，数月后由浊变清，于是一泓泓清洌洌热腾腾的泉水便从山石间的泉眼里喷薄而出，形成现今的天然温泉。池深2尺多，周围用巨石围栏，清澈见底，底部全是细软洁白的细沙层。远看云蒸雾绕，近感热气腾腾，是当地人洗灌的好场所。 双车温泉有1号2号之分：1号位于麻林溪河漫滩，出水口水温60℃，日涌流量270多吨；2号位于麻林溪畈田亩间，水温47℃，日涌流量60多吨。两温泉水质均优，是无色无味略有硫磺质的透明体。此泉可洗刷，可沐浴，早春时还可供浸种催芽。农人们一日劳作之后到此洗个澡，顿觉疲劳消尽精神焕发，还可防治皮肤病。据传，解放前闽西地区疥疮病猖獗，而此地群众却能幸免。大家视温泉为宝，多加爱护。 双车温泉有1号2号之分：1号位于麻林溪河漫滩，出水口水温60℃，日涌流量270多吨；2号位于麻林溪畈田亩间，水温47℃，日涌流量60多吨。两温泉水质均优，是无色无味略有硫磺质的透明体。此泉可洗刷，可沐浴，早春时还可供浸种催芽。农人们一日劳作之后到此洗个澡，顿觉疲劳消尽精神焕发，还可防治皮肤病。据传，解放前闽西地区疥疮病猖獗，而此地群众却能幸免。大家视温泉为宝，多加爱护。",
    "tel": "",
    "id": 1585,
    "vRaddress": "",
    "longitude": "116.932889924936148"
  },
  {
    "area": "45",
    "image": "[{\"name\":\"雪云湖垂钓基地.1.jpg\",\"data\":\"/file/202303/7cd22305bda64c36a5285dc5d07e792c.jpg\",\"uid\":1680231560034,\"status\":\"success\"}]",
    "address": "新罗区江山镇村美村",
    "obj_id": "9",
    "latitude": "25.194181814299093",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[117.020410404619824, 25.191696500737194],[117.020308862840238, 25.191599705357888],[117.019731590221411, 25.191975126451013],[117.019309343460051, 25.19207342731012],[117.018865722957528, 25.192249449620501],[117.018849667819069, 25.192448518109568],[117.018488878566885, 25.192716530331108],[117.018063983802747, 25.192630394281526],[117.017433318737119, 25.192622516021391],[117.016930936858842, 25.192565731239945],[117.016327003068355, 25.192562634718684],[117.015803196780695, 25.193102976955682],[117.015669563595367, 25.193409166853073],[117.015600079452852, 25.193598683339495],[117.015065606268507, 25.193891491769914],[117.015022834765432, 25.194192566056884],[117.01495867188504, 25.194653887320403],[117.014573860668435, 25.194829773556592],[117.013964587997137, 25.194952916205835],[117.013563724374862, 25.195517160075308],[117.013772130266034, 25.195943698187197],[117.01423175351654, 25.195811285782078],[117.014552448622581, 25.195281249802701],[117.015081586034043, 25.19473605276805],[117.015749686942556, 25.19407393641303],[117.015979539539771, 25.193515054367012],[117.016011630058756, 25.193233421290632],[117.016332296781869, 25.193213063821464],[117.016562148933261, 25.19268815261859],[117.018416715432792, 25.192847803995132],[117.018929831844559, 25.192598760772501],[117.01920777627943, 25.192360108572252],[117.019731567677837, 25.192290643990368],[117.020410404619824, 25.191696500737194]]",
    "name": "雪云湖垂钓基地",
    "details": "一条大河波浪宽，风吹稻花香两岸……”每当听到这首悠扬的乐曲，我的脑海里立刻就浮现出这样的画面：一湖春水，静静流淌。水中鱼虾成群，隐约可见；湖面渔船悠悠，渔民撒网；两岸翠竹繁茂，桃红柳绿，这就是我家乡迷人的雪云湖——村美水库。 雪云湖位于新罗区江山镇境内，集水面积93.3平方公里，总库容1079万立方米。水库于1992年12月动工，1995年底建成，是一座以发电为主，兼有灌溉、水产养殖等综合效益的水库 。她对我市的防洪、排涝、供水、灌溉、景观及生态环境保护，特别是新罗区的经济和社会发展起着十分重要的作用。",
    "tel": "",
    "id": 1586,
    "vRaddress": "",
    "longitude": "117.015380896497874"
  },
  {
    "area": "15",
    "image": "[{\"name\":\"雪云湖垂钓基地.jpg\",\"data\":\"/file/202303/501bf1f0cba2449ca9043f212081f9cb.jpg\",\"uid\":1680231576224,\"status\":\"success\"}]",
    "address": "新罗区江山镇村美村",
    "obj_id": "10",
    "latitude": "25.195565731091744",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[117.025600214994995, 25.196373309228093],[117.025771274444125, 25.196287889538155],[117.025851479600433, 25.196018277884729],[117.02610807059213, 25.195888934997065],[117.026110732450491, 25.196010283225529],[117.026289789133429, 25.196293739937698],[117.026682664641854, 25.196688256421783],[117.027011387003981, 25.197114500675671],[117.027019438035381, 25.196706753238619],[117.027088955846438, 25.196259999256448],[117.02712105055187, 25.196017210357624],[117.027217289703145, 25.195779106111921],[117.027372335261703, 25.195506862915799],[117.027473918890905, 25.195232336084],[117.027425826483054, 25.194967927051849],[117.02711312495164, 25.194675111774163],[117.027249429749858, 25.194905300732383],[117.02735096644281, 25.195281209570329],[117.027123764669696, 25.195410465247125],[117.026931305113905, 25.195634273451304],[117.027014139045093, 25.195978674126501],[117.026869795370914, 25.196136823472436],[117.026891146895423, 25.196600318207182],[117.026917858953681, 25.196794398087849],[117.026728103126857, 25.196569216097348],[117.026444809704529, 25.196322442649397],[117.02625239985467, 25.195895817325166],[117.026201633921943, 25.195692094539204],[117.026124130202632, 25.195660759132039],[117.025819415247625, 25.195850902530481],[117.025509356046427, 25.196254634900779],[117.025600214994995, 25.196373309228093]]",
    "name": "雪云湖垂钓基地",
    "details": "一条大河波浪宽，风吹稻花香两岸……”每当听到这首悠扬的乐曲，我的脑海里立刻就浮现出这样的画面：一湖春水，静静流淌。水中鱼虾成群，隐约可见；湖面渔船悠悠，渔民撒网；两岸翠竹繁茂，桃红柳绿，这就是我家乡迷人的雪云湖——村美水库。 雪云湖位于新罗区江山镇境内，集水面积93.3平方公里，总库容1079万立方米。水库于1992年12月动工，1995年底建成，是一座以发电为主，兼有灌溉、水产养殖等综合效益的水库 。她对我市的防洪、排涝、供水、灌溉、景观及生态环境保护，特别是新罗区的经济和社会发展起着十分重要的作用。",
    "tel": "",
    "id": 1587,
    "vRaddress": "",
    "longitude": "117.027145138419598"
  },
  {
    "area": "2",
    "image": "[{\"name\":\"微信图片_20230327171915.png\",\"data\":\"/file/202303/71c2413d559140c888ea0f1d50c2fb8b.png\",\"uid\":1679908959382,\"status\":\"success\"}]",
    "address": "新罗区江山镇山塘村",
    "obj_id": "3",
    "latitude": "25.167889376951443",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.955289207443144, 25.167971030862276],[116.955565990746521, 25.167978904277788],[116.955682325616309, 25.167888824614284],[116.955854812832598, 25.167876297095617],[116.956229200093972, 25.167847530937291],[116.956284026366347, 25.167761230089727],[116.956187760070819, 25.167710471894399],[116.95607945095864, 25.167784756677921],[116.95580801894954, 25.16780964698636],[116.955537920540451, 25.167846669317154],[116.955430954430128, 25.167799574107704],[116.955286543813671, 25.167818104913916],[116.955289207443144, 25.167971030862276]]",
    "name": "江山睡美人观景台",
    "details": "江山睡美人的特色：在蓝天下清晰而细腻地勾勒出一位仰卧的美女。那美女眉清目秀，秀发飘垂，乳峰高耸，颈颔纤美，枕山而眠。由岩顶山、大门崎、笔架山等山峰所构成。睡美人山峦线条清晰，游人至山塘村的睡美人观景台，抬头仰户，可见一天然“美女”，面朝苍天，泰然而卧；“美人”长发披肩，束腰隆胸，活灵活现，形象逼真，令人心驰神往。几千年来，“睡美人”的传说代代相传，是世界上罕见的自然奇观。",
    "tel": "",
    "id": 1672,
    "vRaddress": "https://8dem1b01bss.720yun.com/vr/b5ejOdkmtw1?s=5334276",
    "longitude": "116.955436270691848"
  },
  {
    "area": "20",
    "image": "[{\"name\":\"石山园水库.png\",\"data\":\"/file/202303/64b4ce4360584c7990dba3d6a16307d2.png\",\"uid\":1679986501140,\"status\":\"success\"}]",
    "address": "新罗区江山镇铜砵村",
    "obj_id": "7",
    "latitude": "25.204440520786495",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[116.94395468622244, 25.204829976885637],[116.944243615967906, 25.205091542026157],[116.944420198206913, 25.205023264287764],[116.944784064879627, 25.204818733961698],[116.94509441534818, 25.204672542798814],[116.945094434535562, 25.204395883870486],[116.945062345022706, 25.204187236203779],[116.94483761285943, 25.20423134042294],[116.944500529949082, 25.204018406549967],[116.944489833403409, 25.20394562113859],[116.944596867972777, 25.203673615560156],[116.944388192247686, 25.203596343772421],[116.944307927432334, 25.203630468203531],[116.944083175602501, 25.203854152711198],[116.94413666055928, 25.204198665712987],[116.944040331416829, 25.20438813628633],[116.94395468622244, 25.204829976885637]]",
    "name": "石山园水库",
    "details": "位于江山景区，距龙岩城关25公里。园内多为中、低山及丘陵地貌、最高海拔黄连盂1815.2米，为龙岩市第一高峰。景区呈长条形，面积1712.5公顷。峰峦叠嶂，怪石嶙峋，碧波万顷，溪水潺潺。奇岩、灌丛、密林、梯田、村落，巧妙地组合在一起，构成层次分明、色彩斑斓的自然景观。景点有千米屏障、石观音、一线天、石狮圆梦、黛山闻涛、碧竹丹枫、竹坞鸟语、动物天堂等。其特点为山秀、石奇、林茂、水清，一派大自然风光。",
    "tel": "",
    "id": 1676,
    "vRaddress": "https://8dem1b01bss.720yun.com/vr/b5ejOdkmtw1?s=5374620",
    "longitude": "116.944586085918331"
  },
  {
    "area": "",
    "image": "[{\"name\":\"千米屏障.png\",\"data\":\"/file/202304/64e0b4be0a8b49379511d82a4db30fd9.png\",\"uid\":1681785738954,\"status\":\"success\"}]",
    "address": "新罗区江山镇铜砵村",
    "obj_id": "14",
    "latitude": "25.191306008865203",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[]",
    "name": "千米屏障",
    "details": "坐落在石山园景区内，耸立于铜钵溪畔。海拔标高1100米，相对高差300 余米，总长约1000多米。岩石节理发达，历经风化，一般坡度都在80度以上，形成崖壁陡峭、险峻，称之\"千米屏障\"。壁上有\"石观音\"和\"太公钓鱼\"等奇石。崖壁生长着多种常绿灌木丛，春、秋两季，花繁叶茂，景色更加迷人。修建吊桥、攀岩游步道、空中滑索。",
    "tel": "",
    "id": 1678,
    "vRaddress": "https://8dem1b01bss.720yun.com/vr/b5ejOdkmtw1?s=5374622",
    "longitude": "116.958416738447551"
  },
  {
    "area": "10",
    "image": "[{\"name\":\"103443o0mfpdydkyfff0q4.jpg\",\"data\":\"/file/202303/9907f02c67a7489ea53758ef57495a07.jpg\",\"uid\":1678420171889,\"status\":\"success\"}]",
    "address": "新罗区江山镇村美村",
    "obj_id": "4",
    "latitude": "25.19147559955929",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "",
    "addArr": "[[117.004831787318949, 25.191208652660158],[117.004425631337341, 25.191544817940034],[117.004479044295636, 25.191855323869088],[117.004922583479697, 25.191989896697297],[117.005205837649839, 25.191566727125078],[117.005697509593972, 25.191201186001507],[117.005927317433944, 25.190948072164044],[117.005366207279963, 25.190944917484106],[117.004831787318949, 25.191208652660158]]",
    "name": "九候叠障观景台",
    "details": "九侯叠嶂”位于龙岩城后花园新罗区江山镇，是龙岩中心城区北部重要屏障。据清乾隆三年《龙岩州志》记载，龙岩古代官员“新任官未下车,十里外遥祭”。纵观八闽诸多名山，九侯山成为唯一被写进史志中被官员祭拜的名山，九侯山因此成为政治名山而赋予了更多的传统山水哲学内涵。“万物并育而不相害，道并行而不相悖”。古人敬畏天地山川，将山赋予人性和神性，这是中国人山崇拜的集体意识",
    "tel": "",
    "id": 1686,
    "vRaddress": "https://8dem1b01bss.720yun.com/vr/b5ejOdkmtw1?s=5374670",
    "longitude": "117.004842477306838"
  },
  {
    "area": "15",
    "image": "[{\"name\":\"微信图片_20230327171351.png\",\"data\":\"/file/202303/3163796a988d42108ae65609e28f78c7.png\",\"uid\":1679908659327,\"status\":\"success\"}]",
    "address": "新罗区江山镇山塘村",
    "obj_id": "1",
    "latitude": "25.170074090227896",
    "video": "",
    "type": "9",
    "linkman": "",
    "use_state": "建设中",
    "addArr": "[[116.951538139204231, 25.170344783029012],[116.951728040730401, 25.170249713687209],[116.951992821105662, 25.170338965051837],[116.952131908837501, 25.170273121762563],[116.95257053504524, 25.170235772762343],[116.952602635347603, 25.170199285697393],[116.952682875101999, 25.17004861129049],[116.952792543050663, 25.169851755735028],[116.952819296266171, 25.169703626374254],[116.952680225370557, 25.169723345564801],[116.952396728635762, 25.169670548022125],[116.952073103112284, 25.169724645130252],[116.952027624657248, 25.169867957588703],[116.951931340407356, 25.169848744023252],[116.951883200449856, 25.169825789556665],[116.951699989420504, 25.169833461414264],[116.951682600695122, 25.16994880727658],[116.951567589416484, 25.16991263318258],[116.951381702546158, 25.169915455003519],[116.951255971176337, 25.170240998715606],[116.951538139204231, 25.170344783029012]]",
    "name": "兵工厂广场",
    "details": "龙岩市新罗区江山镇山塘村廖氏宗祠务本堂，始建于清嘉庆十六年（1811），坐北朝南，土木结构，占地面积330平方米。它是中央苏区第一个红色兵工厂。 1927年9月，在郭滴人、邓子恢等共产党人的领导下，山塘乡农民协会在此创办乡兵工厂，主要生产步枪、手榴弹、子弹等。1930年8月，中共闽西特委在邻近的龙门镇湖洋村建立闽西红军兵工厂，山塘兵工厂的人员、设备随之迁入湖洋，并入闽西红军兵工厂。闽西红军兵工厂后来随机构的变迁又先后迁往永定虎岗、长汀四都、汀州、瑞金等地，最后与江西官田修械所合并，在江西省兴国县成立中华苏维埃中央兵工总厂，是当时全国各苏区中最大的军工企业。",
    "tel": "",
    "id": 1788,
    "vRaddress": "https://8dem1b01bss.720yun.com/vr/b5ejOdkmtw1?s=5320074",
    "longitude": "116.952123885604166"
  }
]
export default TravelOnlyArr